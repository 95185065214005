import { Card, Group, Badge, Button, Image, Text, Container } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";

function PortfolioCard({portfolioData}: {portfolioData: PortfolioItem}) {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder style={{height: '100%'}}>
        <Card.Section>
        <Image
            src={portfolioData.imageUrl}
            height={300}
        />
        </Card.Section>
        <Card.Section mb={1} style={{height:"100%"}}>
            <Container mb="lg">
                <Group justify="space-between" mt="md" mb="xs">
                    <Text fw={500}>{portfolioData.label?.title}</Text>
                    <Text fw={350}>{portfolioData.label?.subtitle}</Text>
                {/* <Badge color="pink">On Sale</Badge> */}
                </Group>
                <Group mb="xs" gap={5}>
                    { portfolioData.technologies && portfolioData.technologies.map((tech) => (<Badge fw='normal' color="rgba(66, 177, 189, 1)">{tech}</Badge>))}
                </Group>
                <Text fw={400}>{portfolioData.label?.tagline}</Text>
            </Container>
        </Card.Section>
        <Card.Section mb={1} style={{height:"100%", "align-content": "flex-end"}}>
            <Container>
                {portfolioData.label?.quote && (<Text size="sm" c="dimmed">"{portfolioData.label?.quote}"</Text>)}

                {
                    portfolioData.url && (
                        <Button variant="gradient" 
                                gradient={{ from: 'grape', to: 'rgba(66, 177, 189, 1)', deg: 185 }} 
                                fullWidth   
                                mt="md" 
                                radius="md" 
                                component="a" 
                                href={portfolioData.url} 
                                target="_blank">
                            {portfolioData.buttonText || "Play"}&nbsp;<IconExternalLink size={18} />
                        </Button>
                    )
                }
            </Container>
        </Card.Section>
    </Card>
  );
}

export default PortfolioCard;

export class PortfolioItem {
    imageUrl!: string;
    gameJam?: string;
    name?: string;
    description?: string;
    url?: string;
    buttonText?: string;
    technologies?: string[];
    quotes?: string[];
    label?: Label;
}

export class Label {
    title?: string;
    subtitle?: string;
    tagline?: string;
    quote?: string;
}