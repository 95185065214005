import { Container, Divider, Grid } from "@mantine/core";
import { IconStar } from "@tabler/icons-react";
import PortfolioCard from "components/PortfolioCard/PortfolioCard";
import { useEffect, useState } from "react";


function CardGroup({groupKey}: {groupKey: string}) {
    const [portfolioItemData, setPortfolioItemData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            fetch(`/data/${groupKey}.json`).then(response => {
                                                if (!response.ok) {
                                                throw new Error('Network response was not ok');
                                                }
                                                return response.json();
                                            })  
                                            .then(data => setPortfolioItemData(data))
                                            .catch(error => console.error('There has been a problem with your fetch operation:', error));

        };
    
        fetchData();
      }, [groupKey]);
    
    return (
        <Container size="xl">
            <Grid justify="center" align="stretch">
                <h1>{toTitleCase(groupKey)}</h1>
            </Grid>
            <Grid justify="center" align="stretch">
                {portfolioItemData && portfolioItemData?.map((data) => (
                    <Grid.Col span={{ base: 12, sm: 12, md: 6, lg: 4 }}><PortfolioCard portfolioData={data} /></Grid.Col>
                ))}
            </Grid>
            <Divider my="xl" variant="dashed" labelPosition="center" label={<IconStar size={24} />} />
        </Container>
    );
}

function toTitleCase(str: string) {
    let res = str.replace('-', ' ');
    let result = res.replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
    
    if(str === "dev-ops") 
        result = result.replace(' ', '');
    return result;
  }

export default CardGroup;