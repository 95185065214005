import './App.css';
import '@mantine/core/styles.css';
import { createTheme, MantineProvider } from '@mantine/core';
import { AppShell } from '@mantine/core';
import { HeaderSimple } from './components/header/HeaderSimple';
import CardGroup from 'components/card-group/CardGroup';

const theme = createTheme({
  
  /** Put your mantine theme override here */
});

function App() {
  return (
    <MantineProvider theme={theme} defaultColorScheme="dark">
      <AppShell>
        <AppShell.Header>
          <HeaderSimple></HeaderSimple>
        </AppShell.Header>

        <AppShell.Main mt={{base:65, sm:100}}>

        <CardGroup groupKey="game-development"></CardGroup>

        <CardGroup groupKey="dev-ops"></CardGroup>

        <CardGroup groupKey="mods"></CardGroup>

        <CardGroup groupKey="game-jams"></CardGroup>

        <CardGroup groupKey="personal-projects"></CardGroup>
          
        </AppShell.Main>
      </AppShell>
    </MantineProvider>
  );
}

export default App;
