import { Container, Group, rem, Image, ActionIcon, Title } from '@mantine/core';
import { IconBrandItch, IconBrandLinkedin, IconBrandX } from '@tabler/icons-react';

const actionIcons = [
  { component: IconBrandLinkedin, link: 'https://www.linkedin.com/in/romeshwickramasekera' },
  { component: IconBrandItch, link: 'https://chickencastle.itch.io/' },
  { component: IconBrandX, link: 'https://twitter.com/castle_chicken' },
];

export function HeaderSimple() {

  const externalLinks = actionIcons.map((icon) => (
    <div>
      <ActionIcon hiddenFrom="sm"
                  size={30}
                  variant="gradient" 
                  gradient={{ from: 'grape', to: 'rgba(66, 177, 189, 1)', deg: 185 }} 
                  component="a" 
                  href={icon.link} 
                  target="_blank">
          <icon.component style={{width:rem(18),height:rem(18)}}/>
      </ActionIcon>

      <ActionIcon visibleFrom="sm"
                  size={42}
                  variant="gradient" 
                  gradient={{ from: 'grape', to: 'rgba(66, 177, 189, 1)', deg: 185 }} 
                  component="a" 
                  href={icon.link} 
                  target="_blank">
          <icon.component style={{width:rem(24),height:rem(24)}}/>
      </ActionIcon>
    </div>
  ));

  return (
    <header className="header">
      <Container size="xl" className="inner">
        <Group gap={15}>
          <Image h={{base:25, sm:40}} src="favicon.ico" />
          <Title hiddenFrom="sm" size="h3" mt=".67em" mb=".67em">Romesh.dev</Title>
          <Title visibleFrom="sm" size="h1" mt=".67em" mb=".67em">Romesh.dev</Title>
        </Group>
        <Group gap={10}>
          {externalLinks}
        </Group>
      </Container>
    </header>
  );
}